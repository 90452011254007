"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Mapa = void 0;
//import * as loadGoogle.mapsApi from "load-google-maps-api";
var $ = require("jquery");
var config_1 = require("./config");
require("../assets/js/maplabel.js");
var _ = require("lodash");
var form_map_layout_1 = require("./form.map-layout");
var i18next = require("i18next");
var Mapa = /** @class */ (function () {
    function Mapa(moduleId) {
        this.drawingManager = [];
        this.surfaceCoordinates = [[]];
        this.areaM2 = [];
        this.polygons = [];
        this.mapLabel = [];
        this.moduleArea = [];
        this.modulesPolygons = [];
        this.modulesPolygonsLayout = [];
        this.phase = config_1.config.context.defaultPhases;
        this.maxSurface = 10000;
        this.minSurface = 1;
        this.validAreas = false;
        this.typeKits = "monophasic";
        this.moduleId = moduleId;
        this.country = "España";
        this.nAreas = 0;
        this.setEvents();
        this.init();
    }
    Mapa.prototype.init = function () {
        this.areaM2[0] = 0;
        this.resizeMap();
        this.map = new google.maps.Map(document.getElementById("mapa"), {
            zoom: 7,
            center: {
                lat: 41,
                lng: 2,
            },
            mapTypeId: google.maps.MapTypeId.SATELLITE,
            disableDefaultUI: true,
            zoomControl: true,
            tilt: 0,
        });
        // Iniciamos el objeto de Google Maps que nos permite obtener los datos de una dirección
        this.geocoder = new google.maps.Geocoder();
        // Iniciamos el objeto de Google Maps que nos permite obtener la elevación de un punto
        this.elevator = new google.maps.ElevationService();
        this.createDrawingManager();
        this.addListenersTodrawingManager();
        this.getProducts();
        this.mapLayout = new form_map_layout_1.MapLayout(this.product, config_1.config);
    };
    Mapa.prototype.getPhase = function () {
        return this.phase;
    };
    /**
     * Get filtered products and set available products lists
     * @param references product references
     */
    Mapa.prototype.getProducts = function () {
        var that = this;
        if (that.typeOfKits == "canarias") {
            that.moduleId = config_1.config.context.moduleIdCanarias;
        }
        else {
            that.moduleId = config_1.config.context.moduleIdResto;
        }
        var references = [
            config_1.config.context.flatStructureRef,
            config_1.config.context.inclinationStructureRef,
        ];
        var filter = {
            where: {
                domainId: config_1.config.context.domainId,
            },
            fields: [
                "id",
                "reference",
                "properties",
                "embeddedProducts",
                "typeId",
                "description",
                "name",
            ],
            include: [
                {
                    relation: "products",
                    scope: {
                        include: [{ relation: "product" }],
                    },
                },
            ],
        };
        if (!this.moduleId) {
            references.push(config_1.config.context.moduleRef);
            _.merge(filter.where, { reference: { inq: references } });
        }
        else {
            _.merge(filter.where, {
                or: [
                    { reference: { inq: references } },
                    { id: this.moduleId },
                    { typeId: "KIT" },
                ],
            }, { statusId: "PUBLISHED" });
        }
        this.getAjax(config_1.config.backendHost + "/Products", filter, function (data) {
            //Get module
            if (data) {
                var product = data.find(function (property) {
                    return property.id === that.moduleId ||
                        property.reference === config_1.config.context.moduleRef;
                });
                if (product) {
                    var length_1 = product.properties ? product.properties.length : null;
                    var width = product.properties ? product.properties.width : null;
                    var modules_power_output = product.properties
                        ? product.properties.modules_power_output
                        : null;
                    that.moduleId = product.id;
                    that.product = {
                        properties: {
                            length: parseInt(length_1),
                            width: parseInt(width),
                            modules_power_output: modules_power_output,
                        },
                    };
                }
                //Get structure
                var flatStructure = data.find(function (property) { return property.reference === config_1.config.context.flatStructureRef; });
                var inclinationStructure = data.find(function (property) {
                    return property.reference === config_1.config.context.inclinationStructureRef;
                });
                that.flatStructure = flatStructure.id;
                that.inclinationStructure = inclinationStructure.id;
                that.parseKits(data);
            }
            else {
                console.error("Error on get Products");
            }
        });
    };
    Mapa.prototype.parseKits = function (data) {
        var _this = this;
        this.parsedKits = { batteries: [], monophasic: [], triphasic: [] };
        var kits = _.filter(data, function (kit) {
            if (_this.typeOfKits == "canarias") {
                return (kit.typeId === "KIT" &&
                    kit.description &&
                    kit.description.includes("#canarias") &&
                    !kit.description.includes("#premium") &&
                    !kit.description.includes("#LeroyMerlin"));
            }
            else {
                return (kit.typeId === "KIT" &&
                    kit.description &&
                    (kit.description.includes("#baterias") ||
                        kit.description.includes("#Endesa")) &&
                    !kit.description.includes("#premium") &&
                    !kit.description.includes("#canarias") &&
                    !kit.description.includes("#LeroyMerlin"));
            }
        });
        if (kits) {
            var modulesNumber = void 0;
            var phase = void 0;
            var modulesPower = void 0;
            for (var _i = 0, kits_1 = kits; _i < kits_1.length; _i++) {
                var kit = kits_1[_i];
                for (var _a = 0, _b = kit.products; _a < _b.length; _a++) {
                    var relatedProduct = _b[_a];
                    if (relatedProduct &&
                        relatedProduct.product &&
                        relatedProduct.product.category) {
                        var code = relatedProduct.product.category.code;
                        switch (code) {
                            case "PV_MODULE":
                                modulesNumber = relatedProduct.count;
                                modulesPower =
                                    relatedProduct.product &&
                                        relatedProduct.product.properties &&
                                        relatedProduct.product.properties.modules_power_output;
                                break;
                            case "INVERTER":
                                phase =
                                    relatedProduct.product && relatedProduct.product.properties
                                        ? relatedProduct.product.properties.phase
                                        : "1";
                                break;
                            case "INVERTER_RED":
                                phase =
                                    relatedProduct.product && relatedProduct.product.properties
                                        ? relatedProduct.product.properties.phase.toString()
                                        : "1";
                                break;
                            default:
                                break;
                        }
                    }
                }
                if (kit.description.includes("#baterias")) {
                    this.parsedKits.batteries.push({
                        name: kit.name,
                        modulesNumber: modulesNumber,
                        modulesPower: modulesPower,
                        phase: phase,
                    });
                }
                else {
                    if (phase === "1") {
                        this.parsedKits.monophasic.push({
                            name: kit.name,
                            modulesNumber: modulesNumber,
                            modulesPower: modulesPower,
                            phase: phase,
                        });
                    }
                    else {
                        this.parsedKits.triphasic.push({
                            name: kit.name,
                            modulesNumber: modulesNumber,
                            modulesPower: modulesPower,
                            phase: phase,
                        });
                    }
                }
            }
            this.parsedKits.batteries = _.orderBy(this.parsedKits.batteries, ["modulesNumber"], ["asc"]);
            this.parsedKits.monophasic = _.orderBy(this.parsedKits.monophasic, ["modulesNumber"], ["asc"]);
            this.parsedKits.triphasic = _.orderBy(this.parsedKits.triphasic, ["modulesNumber"], ["asc"]);
        }
    };
    /**
     * Send a HTTP GET request
     * @param {*} url
     * @param {*} success
     */
    Mapa.prototype.getAjax = function (url, params, success) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url + "?filter=" + JSON.stringify(params), false);
        xhr.setRequestHeader("X-Access-Token", config_1.config.apiKey);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    success(JSON.parse(xhr.responseText));
                }
                else {
                    success();
                }
            }
        };
        xhr.send();
    };
    Mapa.prototype.createDrawingManager = function () {
        this.drawingManager[this.nAreas] = new (google.maps.drawing.DrawingManager)({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: false,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            },
            polygonOptions: {
                id: this.mongoObjectId(),
                fillColor: getComputedStyle(document.documentElement).getPropertyValue("--areaColor"),
                strokeColor: getComputedStyle(document.documentElement).getPropertyValue("--areaColor"),
                fillOpacity: 0.6,
                strokeWeight: 5,
                clickable: false,
                editable: true,
                zIndex: 1,
            },
        });
        this.mapLabel[this.nAreas] = new MapLabel({
            text: "",
            map: this.map,
            fontSize: 20,
            align: "center",
            zIndex: 5,
        });
        this.drawingManager[this.nAreas].setMap(this.map);
    };
    Mapa.prototype.addListenersTodrawingManager = function () {
        var _this = this;
        var that = this;
        google.maps.event.addListener(this.drawingManager[this.nAreas], "polygoncomplete", function (polygon) {
            $(".map-information").show();
            $(".map-instructions").hide();
            $(".tools").show();
            $(".tools ul").show();
            $(".map-collapse").addClass("vis-mobile");
            if ($(".map-collapse").hasClass("collapsed")) {
                $(".map-collapse").toggleClass("collapsed");
                $(".map-information .user-information").collapse("toggle");
            }
            if (_this.validAreas) {
                _this.areaM2[_this.nAreas] = google.maps.geometry.spherical.computeArea(polygon.getPath());
                _this.calculateSavings();
                _this.resizeMap();
            }
            google.maps.event.addListener(polygon, "dblclick", function (event) {
                if (event.vertex == undefined) {
                    return;
                }
                else {
                    if (polygon.getPath().j.length > 3) {
                        _this.removeVertex(polygon, event.vertex);
                    }
                }
            });
        });
        google.maps.event.addListener(this.drawingManager[this.nAreas], "overlaycomplete", function (polygon) {
            if (polygon.type != google.maps.drawing.OverlayType.MARKER) {
                var that_1 = _this;
                var newArea = void 0;
                // Switch back to non-drawing mode after drawing a shape.
                _this.drawingManager[_this.nAreas].setDrawingMode(null);
                // Add an event listener that selects the newly-drawn shape when the user
                // mouses down on it.
                _this.newShape = polygon.overlay;
                var polygonBounds_1 = _this.newShape.getPath();
                _this.currentSurfaceAzimuth = _this.computeAzimuth(polygonBounds_1);
                var totalSurface = google.maps.geometry.spherical.computeArea(polygonBounds_1);
                if (totalSurface > _this.maxSurface)
                    _this.showMessageSizeArea(_this.newShape, polygonBounds_1, polygon, "big");
                else if (totalSurface < _this.minSurface)
                    _this.showMessageSizeArea(_this.newShape, polygonBounds_1, polygon, "small");
                else {
                    var newArea_1 = _this.createModuleAreaObject(_this.newShape);
                    _this.newShape.type = polygon.type;
                    _this.newShape.id = newArea_1.id;
                    _this.polygons.push(_this.newShape);
                    var areaPolygon = _.find(_this.polygons, function (polygon) {
                        return polygon.id == newArea_1.id;
                    });
                    var layoutData = _this.mapLayout.drawModules(true, newArea_1, areaPolygon, _this.modulesPolygons, _this.polygons, _this.moduleArea);
                    newArea_1.maxModules = layoutData.maxModules;
                    _this.totalModules = layoutData.totalModules;
                    _this.totalPower = layoutData.totalPower;
                    _this.totalSurface = layoutData.totalSurface;
                    _this.createModulesObject(newArea_1);
                    // para back
                    polygonBounds_1.id = _this.nAreas;
                    _this.getPathBounds(polygonBounds_1, _this.nAreas);
                    _this.areaM2[_this.nAreas] =
                        google.maps.geometry.spherical.computeArea(polygonBounds_1);
                    _this.setMapLabel(polygonBounds_1, polygonBounds_1.id);
                    _this.validAreas = true;
                    //polygon edit events - set, remove and insert node
                    google.maps.event.addListener(polygonBounds_1, "set_at", function () {
                        that_1.actionEditAreaEvent(polygonBounds_1, "set_at");
                    });
                    google.maps.event.addListener(polygonBounds_1, "remove_at", function () {
                        that_1.actionEditAreaEvent(polygonBounds_1, "remove_at");
                    });
                    google.maps.event.addListener(polygonBounds_1, "insert_at", function () {
                        that_1.actionEditAreaEvent(polygonBounds_1, "insert_at");
                    });
                    $("#submitStep2 .send-button").prop("disabled", false);
                }
            }
        });
    };
    /**
     * If area is bigger than 'maxSurface' it show an error message in the middle of the area
     * @param newShape drawn area
     * @param polygonBounds polygon bounds of the area
     * @param polygon polygon to get the map
     * @param size if its big or small area
     */
    Mapa.prototype.showMessageSizeArea = function (newShape, polygonBounds, polygon, size) {
        // newShape.id = this.StringUtils.mongoObjectId();
        this.polygons.push(newShape);
        this.drawingManager[this.nAreas].setDrawingMode(null);
        var contentString = size == "big"
            ? "<div id='" +
                newShape.id +
                "' class='alertArea'>Àrea demasiado grande, dibuje un àrea menor</div>"
            : "<div id='" +
                newShape.id +
                "' class='alertArea'>Àrea demasiado pequeña, dibuje un àrea mayor</div>";
        var infowindow = new google.maps.InfoWindow({
            content: contentString,
            position: this.getCenterInPolygon(polygonBounds),
        });
        infowindow.open(polygon.overlay.getMap());
        this.setEventInfoWindowArea(infowindow);
        this.validAreas = false;
        $(".add-area").addClass("disabled");
        $("#submitStep2 .send-button").prop("disabled", true);
        if (this.moduleArea.length == 0) {
            $(".resp-inf .unit span:first").text("-");
            $(".resp-inf .kilowatt-max span:first").text("-");
            $(".resp-inf .eur span:first").text("-");
            $(".resp-inf .co2 span:first").text("-");
        }
    };
    /**
     * Set event onclick close window and delete all areas
     * @param infowindow info window when area is too big
     */
    Mapa.prototype.setEventInfoWindowArea = function (infowindow) {
        var _this = this;
        google.maps.event.addListener(infowindow, "domready", function () {
            $(".gm-ui-hover-effect").on("click", function (event) {
                var content = infowindow.getContent();
                var doc = new DOMParser().parseFromString(content.toString(), "text/xml");
                var areaId = doc.getElementsByClassName("alertArea")[0].id;
                _this.removeArea(areaId);
                _this.drawingManager[_this.nAreas].setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
                if (_this.moduleArea.length > 0) {
                    _this.validAreas = true;
                    $("#submitStep2 .send-button").prop("disabled", false);
                }
                $(".add-area").removeClass("disabled");
            });
        });
        $(".reset-map-drawings").on("click", function () {
            infowindow.close();
            $(".add-area").removeClass("disabled");
        });
        $("#step2 .map-address-modify button").on("click", function () {
            infowindow.close();
            $(".add-area").removeClass("disabled");
        });
    };
    Mapa.prototype.removeArea = function (areaId) {
        var polygon = _.find(this.polygons, function (obj) { return obj.id === areaId; });
        polygon.setMap(null);
        _.remove(this.polygons, function (obj) { return obj.id === areaId; });
    };
    Mapa.prototype.actionEditAreaEvent = function (polygonBounds, eventType) {
        var _this = this;
        this.getPathBounds(polygonBounds, polygonBounds.id, function (polyShapeArray) {
            _this.moduleArea[polygonBounds.id].polyshapeArray = polyShapeArray;
            var areaPolygon = _.find(_this.polygons, function (polygon) {
                return polygon.id == _this.moduleArea[polygonBounds.id].id;
            });
            areaPolygon.setPath(polygonBounds);
            var modulesRemoved = _.remove(_this.modulesPolygons, function (module) {
                return module.areaId === areaPolygon.id;
            });
            for (var _i = 0, modulesRemoved_1 = modulesRemoved; _i < modulesRemoved_1.length; _i++) {
                var moduleRemoved = modulesRemoved_1[_i];
                moduleRemoved.setMap(null);
            }
            _.remove(_this.modulesPolygonsLayout, function (module) {
                return module.areaId === areaPolygon.id;
            });
            _this.moduleArea[polygonBounds.id].surface =
                google.maps.geometry.spherical.computeArea(polygonBounds);
            var layoutData = _this.mapLayout.drawModules(true, _this.moduleArea[polygonBounds.id], areaPolygon, _this.modulesPolygons, _this.polygons, _this.moduleArea);
            _this.moduleArea[polygonBounds.id].maxModules = layoutData.maxModules;
            _this.totalModules = layoutData.totalModules;
            _this.totalPower = layoutData.totalPower;
            _this.totalSurface = layoutData.totalSurface;
            _this.createModulesObject(_this.moduleArea[polygonBounds.id]);
        });
        this.areaM2[polygonBounds.id] =
            google.maps.geometry.spherical.computeArea(polygonBounds);
        this.setMapLabel(polygonBounds, polygonBounds.id);
        var that = this;
        that.calculateRecommend();
        if (eventType == "set_at")
            this.drawingManager[polygonBounds.id].setMap(this.map);
        if ($(".map-collapse").hasClass("collapsed")) {
            $(".map-collapse").toggleClass("collapsed");
            $(".map-information .user-information").collapse("toggle");
        }
    };
    Mapa.prototype.createModulesObject = function (targetArea) {
        var _this = this;
        this.modulesPolygons.forEach(function (moduleObject) {
            if (moduleObject.areaId === targetArea.id) {
                var boundsArray = [];
                var id = moduleObject.id;
                var paths = moduleObject.getPath();
                for (var j = 0; j < paths.getLength(); j++) {
                    boundsArray.push({
                        lat: paths.getAt(j).lat(),
                        lng: paths.getAt(j).lng(),
                    });
                }
                var layoutModule = {
                    id: id,
                    areaId: targetArea.id,
                    productId: targetArea.moduleId,
                    bounds: boundsArray,
                    active: moduleObject.active,
                };
                _this.modulesPolygonsLayout.push(layoutModule);
            }
        });
    };
    /**
     * Duplicate Module Area Object when create a second area
     */
    Mapa.prototype.duplicateModuleAreaObject = function (newShape, area) {
        var _this = this;
        var polygonBounds = newShape.getPath();
        var length = this.moduleArea.push({
            name: "Area " + (this.moduleArea.length + 1),
            id: newShape.id,
            azimuth: area.azimuth,
            vertical: true,
            south: true,
            surface: google.maps.geometry.spherical.computeArea(polygonBounds),
            surfaceTilt: area.surfaceTilt,
            surfaceAzimuth: area.surfaceAzimuth,
            modulesTilt: area.modulesTilt,
            tilt: area.tilt,
            shades: area.shades,
            rowDistance: area.rowDistance,
            columnDistance: area.columnDistance,
            totalStorage: area.totalStorage,
            activeModules: area.activeModules,
            polyshapeArray: [],
            modulePower: this.product.properties.modules_power_output,
            moduleSurface: (this.product.properties.length / 1000) *
                (this.product.properties.width / 1000),
            moduleId: this.moduleId,
            structureId: $("#menu li.current").attr("value") == "Plana"
                ? this.flatStructure
                : this.inclinationStructure,
            moduleMatrixDisposition: "row",
            moduleMatrixHorizontal: 1,
            moduleMatrixVertical: 1,
            maxModules: area.maxModules,
            visible: true,
        });
        polygonBounds.forEach(function (element) {
            _this.moduleArea[length - 1].polyshapeArray.push({
                lat: element.lat(),
                lng: element.lng(),
            });
        });
        return this.moduleArea[length - 1];
    };
    /**
     * Calculates the azimuth based on the longest polygon side
     * @param polygonBounds Polygon vertices
     * @returns Integer tilt of the longest polygon side
     */
    Mapa.prototype.computeAzimuth = function (polygonBounds) {
        var polygonBoundsArray = polygonBounds.getArray();
        var sides = _.map(polygonBoundsArray, function (coords) {
            var nextCoords = _.get(polygonBoundsArray, (_.indexOf(polygonBoundsArray, coords) + 1) % polygonBoundsArray.length);
            var distance = google.maps.geometry.spherical.computeDistanceBetween(coords, nextCoords);
            return distance;
        });
        var longestSideIndex = _.indexOf(sides, _.max(sides));
        var longestSides = _.map([longestSideIndex, (longestSideIndex + 1) % polygonBoundsArray.length], function (index) { return _.get(polygonBoundsArray, index); });
        var angle = google.maps.geometry.spherical.computeHeading(longestSides[0], longestSides[1]);
        var roundAngle = _.round(angle);
        if (roundAngle < 0 && roundAngle > -90) {
            roundAngle = -90 - roundAngle;
        }
        else if (roundAngle > 0 && roundAngle < 90) {
            roundAngle = 90 - roundAngle;
        }
        else if (roundAngle < -90) {
            roundAngle += 90;
            roundAngle *= -1;
        }
        else if (roundAngle > 90) {
            roundAngle -= 90;
            roundAngle *= -1;
        }
        else {
            roundAngle = 0;
        }
        return roundAngle;
    };
    /**
     * Create Module Area Object
     */
    Mapa.prototype.createModuleAreaObject = function (newShape) {
        var _this = this;
        var polygonBounds = newShape.getPath();
        var roundAngle = this.currentSurfaceAzimuth;
        $("#azimuthInput").val(roundAngle);
        if (roundAngle > 0) {
            $(".azimuthValue").text(roundAngle + "ºE");
            if (roundAngle < 10) {
                $(".azimuthValue").css("margin-right", "11px");
                $(".azimuthForm output").css("margin-left", "15px");
            }
            else {
                $(".azimuthValue").css("margin-right", "7px");
                $(".azimuthForm output").css("margin-left", "11px");
            }
        }
        else if (roundAngle < 0) {
            $(".azimuthValue").text(roundAngle + "ºO");
            if (roundAngle > -10) {
                $(".azimuthValue").css("margin-right", "7px");
                $(".azimuthForm output").css("margin-left", "12px");
            }
            else {
                $(".azimuthValue").css("margin-right", "1px");
                $(".azimuthForm output").css("margin-left", "9px");
            }
        }
        else {
            $(".azimuthValue").text(roundAngle + "ºS");
            $(".azimuthForm output").css("margin-left", "15px");
        }
        var length = this.moduleArea.push({
            name: "Area " + (this.moduleArea.length + 1),
            id: newShape.id,
            azimuth: roundAngle,
            vertical: true,
            south: true,
            surface: google.maps.geometry.spherical.computeArea(polygonBounds),
            surfaceTilt: 20,
            surfaceAzimuth: roundAngle,
            modulesTilt: 0,
            tilt: 20,
            shades: 0,
            rowDistance: 0,
            columnDistance: 0,
            totalStorage: 0,
            activeModules: 0,
            polyshapeArray: [],
            modulePower: this.product.properties.modules_power_output,
            moduleSurface: (this.product.properties.length / 1000) *
                (this.product.properties.width / 1000),
            moduleId: this.moduleId,
            structureId: this.inclinationStructure,
            moduleMatrixDisposition: "row",
            moduleMatrixHorizontal: 1,
            moduleMatrixVertical: 1,
            maxModules: 0,
            visible: true,
            smallArea: false,
        });
        polygonBounds.forEach(function (element) {
            _this.moduleArea[length - 1].polyshapeArray.push({
                lat: element.lat(),
                lng: element.lng(),
            });
        });
        return this.moduleArea[length - 1];
    };
    Mapa.prototype.clearListeners = function () {
        for (var i = 1; i <= this.nAreas; i++) {
            google.maps.event.clearInstanceListeners(this.drawingManager[i]);
        }
    };
    Mapa.prototype.findCodeAddress = function (array, code) {
        return _.find(array, function (item) {
            return (_.findIndex(item.types, function (type) {
                return type === code;
            }) !== -1);
        });
    };
    Mapa.prototype.searchInMap = function (places) {
        this.resizeMap();
        $(".map-instructions").show();
        this.geocodeAddress(places);
    };
    Mapa.prototype.geocodeAddress = function (places) {
        var _this = this;
        this.map.setZoom(28);
        this.map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
        this.map.setCenter(new google.maps.LatLng(places.geometry.location.lat(), places.geometry.location.lng()));
        // Guardamos las coordenadas
        this.coordinates = places.geometry.location;
        // Obtenemos la elevación del punto seleccionado
        this.elevator.getElevationForLocations({
            locations: [
                new google.maps.LatLng(places.geometry.location.lat(), places.geometry.location.lng()),
            ],
        }, function (results, status) {
            _this.elevation = results[0].elevation;
        });
    };
    Mapa.prototype.numberWithPoints = function (x) {
        if (x != undefined) {
            x = Math.floor(x);
            x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        else {
            x = 0;
        }
        return x;
    };
    Mapa.prototype.getPathBounds = function (polygonBounds, curPolygon, callback) {
        // guardamos las coordenadas de cada punto del polígono
        this.surfaceCoordinates[curPolygon] = [];
        for (var i = 0; i < polygonBounds.length; i++) {
            this.surfaceCoordinates[curPolygon].push({
                lat: polygonBounds.getAt(i).lat(),
                lng: polygonBounds.getAt(i).lng(),
            });
        }
        if (callback)
            callback(this.surfaceCoordinates[curPolygon]);
    };
    Mapa.prototype.getSurface = function () {
        var floors = Number($("#map-floors").val());
        var surface = this.areaM2[0] * (floors === 0 ? 1 : floors);
        return Math.round(surface);
    };
    Mapa.prototype.saveSurfaceArea = function () {
        var surf = this.getSurface();
        if (surf !== 0) {
            $("#dimensions").val(surf);
        }
    };
    Mapa.prototype.resizeMap = function () {
        var mapElement = document.getElementById("mapa");
        mapElement.style.display = "block";
        mapElement.style.height = window.innerHeight + "px";
        mapElement.style.minHeight =
            document.getElementsByClassName("map-controls")[0].scrollHeight + "px";
        google.maps.event.trigger(this.map, "resize");
    };
    Mapa.prototype.resetMap = function () {
        for (var i = 0; i < this.polygons.length; i++) {
            this.polygons[i].setMap(null);
        }
        this.polygons = [];
        for (var i = 0; i <= this.nAreas; i++) {
            this.drawingManager[i].setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
            if (i != 0) {
                this.drawingManager[i].setMap(null);
            }
        }
        this.areaM2 = [];
        /*this.drawingManager.setOptions({
                        drawingControl: true
                    });*/
        this.surfaceCoordinates = [[]];
        for (var i = 0; i <= this.nAreas; i++) {
            this.mapLabel[i].set("text", "");
        }
        this.nAreas = 0;
        $(".tools").hide();
        $(".map-collapse").removeClass("vis-mobile");
        $(".map-instructions").show();
        var submitStep2 = document.getElementsByClassName("map-information");
        var mapInstructions = (document.getElementsByClassName("map-instructions"));
        if (submitStep2[0]) {
            submitStep2[0].style.display = "none";
            mapInstructions[0].style.display = "block";
        }
        this.moduleArea = [];
        for (var _i = 0, _a = this.modulesPolygons; _i < _a.length; _i++) {
            var removedModulePolygons = _a[_i];
            removedModulePolygons.setMap(null);
        }
        this.modulesPolygons = [];
        this.modulesPolygonsLayout = [];
    };
    Mapa.prototype.getLanguage = function () {
        var lang = i18next.default.options.fallbackLng[0];
        if (i18next.default.language != undefined) {
            lang = i18next.default.language;
        }
        return lang;
    };
    Mapa.prototype.setEvents = function () {
        var _this = this;
        var that = this;
        $(".reset-map-drawings").on("click", function () {
            $("#menu li").removeClass("current");
            $('#menu li[ value="1"]').addClass("current");
            $(".tiltValue").attr("data-i18n", "[title]step2.MONOPHASE");
            $(".straight-roof").css("width", "140px");
            $("#azimuthInput").val("0");
            $("#azimuthOutput").text("0");
            $(".azimuthValue").text("0ºS");
            $("#menu-batteries li").removeClass("current");
            $('#menu-batteries li[ value="0"]').addClass("current");
            that.typeKits = "monophasic";
            var totalModules = that.checkConsumptionRange();
            that.mapLayout.setMaxActiveModules(totalModules);
            $;
            _this.resetMap();
            $(".reset-map-drawings").attr("data-i18n", "[title]step2.DELETE_AREA");
            $(".reset-map-drawings span").attr("data-i18n", "step2.DELETE_AREA");
            $("#step2").localize();
        });
        // config.showInclination
        //   ? $(".straight-roof").on("click", () => {
        //       if ($(".straight-roof").hasClass("selected")) {
        //         $(".straight-roof").removeClass("selected");
        //         $(".dropdown-tilt-container").css("display", "none");
        //         $(".tiltValue").css("color", "var(--mainColorLighten)");
        //       } else {
        //         $(".straight-roof").addClass("selected");
        //         $(".dropdown-tilt-container").css("display", "inline-block");
        //         $(".tiltValue").css("color", "white");
        //         if (screen.width <= 480) {
        //           $(".dropdown-azimuth-container").css("display", "none");
        //           $(".sloped-roof").removeClass("selected");
        //         }
        //       }
        //     })
        //   : $(".straight-roof").css("display", "none");
        config_1.config.showBatteries
            ? $(".straight-roof").on("click", function () {
                if ($(".straight-roof").hasClass("selected")) {
                    $(".straight-roof").removeClass("selected");
                    $(".dropdown-batteries-container").css("display", "none");
                    $(".tiltValue").css("color", "var(--mainColorLighten)");
                }
                else {
                    $(".straight-roof").addClass("selected");
                    $(".dropdown-batteries-container").css("display", "inline-block");
                    $(".tiltValue").css("color", "white");
                    if (screen.width <= 480) {
                        $(".dropdown-azimuth-container").css("display", "none");
                        $(".sloped-roof").removeClass("selected");
                    }
                }
            })
            : $(".straight-roof").css("display", "none");
        $("#azimuthInput").on("change", function () {
            $(".dropdown-azimuth-container").css("display", "none");
            that.calculateRecommend();
        });
        config_1.config.showOrientation
            ? $(".sloped-roof").on("click", function () {
                if ($(".sloped-roof").hasClass("selected")) {
                    $(".sloped-roof").removeClass("selected");
                    $(".dropdown-azimuth-container").css("display", "none");
                    $(".azimuthValue").css("color", "var(--mainColorLighten)");
                }
                else {
                    $(".sloped-roof").addClass("selected");
                    $(".dropdown-azimuth-container").css("display", "inline-block");
                    $(".azimuthValue").css("color", "white");
                    if (screen.width <= 480) {
                        $(".dropdown-tilt-container").css("display", "none");
                        $(".straight-roof").removeClass("selected");
                    }
                }
            })
            : $(".sloped-roof").css("display", "none");
        if (!config_1.config.showCalculations)
            $(".calculations").css("display", "none");
        $("#menu-batteries li").click(function () {
            var $this = $(this);
            $("ul li.current").removeClass("current");
            $this.closest("li").addClass("current");
            if ($this.attr("value") === "1") {
                $(".batteriesValue").text("Si");
                that.typeKits = "batteries";
            }
            else {
                $(".batteriesValue").text("No");
                that.typeKits = "monophasic";
            }
            that.calculateRecommend();
        });
        // $("#menu li").click(function () {
        //   var $this = $(this);
        //   $("ul li.current").removeClass("current");
        //   $this.closest("li").addClass("current");
        //   const lang: string = that.getLanguage();
        //   that.phase = $this.attr("value");
        //   if ($this.attr("value") === "1") {
        //     $(".tiltValue").text(lang === "es" ? "Monofásico" : "Monofàsic");
        //     $("#consumeKwInput").attr("min", 125);
        //     $("#consumeKwInput").attr("value", $("#consumeKwInput").val());
        //     $("#consumeKwInput").attr("max", 999);
        //     $(".minConsumeKw").text("125kWh");
        //     $("#consumeKwpOutput").text(_.toString($("#consumeKwInput").val()));
        //     $(".maxConsumeKw").text("999kWh");
        //     $("#consumeInput").attr("min", 40);
        //     $("#consumeInput").attr("value", $("#consumeInput").val());
        //     $("#consumeInput").attr("max", 285);
        //     $(".minConsume").text("40€");
        //     $("#consumeOutput").text(_.toString($("#consumeInput").val()));
        //     $(".maxConsume").text("285€");
        //   } else {
        //     $(".tiltValue").text(lang === "es" ? "Trifásico" : "Trifàsic");
        //     $("#consumeKwInput").attr("min", 420);
        //     $("#consumeKwInput").attr("value", $("#consumeKwInput").val());
        //     $("#consumeKwInput").attr("max", 2080);
        //     $(".minConsumeKw").text("420kWh");
        //     $("#consumeKwpOutput").text(_.toString($("#consumeKwInput").val()));
        //     $(".maxConsumeKw").text("2080kWh");
        //     $("#consumeInput").attr("min", 122);
        //     $("#consumeInput").attr("value", $("#consumeInput").val());
        //     $("#consumeInput").attr("max", 600);
        //     $(".minConsume").text("122€");
        //     $("#consumeOutput").text(_.toString($("#consumeInput").val()));
        //     $(".maxConsume").text("600€");
        //   }
        //   if ($(".euros-consumption").hasClass("checked")) {
        //     that.offer.powerPlantData.supplyPoint.totalConsumption =
        //       ($("#consumeInput").val() * 12) / 0.289849667;
        //   } else {
        //     that.offer.powerPlantData.supplyPoint.totalConsumption =
        //       $("#consumeKwInput").val() * 12;
        //   }
        //   that.calculateRecommend();
        // });
        $("#azimuthInput").on("input", function () {
            var cardinal;
            var name;
            var data = {};
            var $this = $(this);
            var value = parseInt($this.val());
            name = $("#azimuthInput").attr("name");
            data = that.calculateTiltAzimuth(name, value);
            that.reDrawModules(data);
            if (value > 0) {
                cardinal = "E";
                if (value < 10) {
                    $(".azimuthValue").css("margin-right", "11px");
                    $(".azimuthForm output").css("margin-left", "15px");
                }
                else {
                    $(".azimuthValue").css("margin-right", "7px");
                    $(".azimuthForm output").css("margin-left", "11px");
                }
            }
            else {
                if (value == 0) {
                    cardinal = "S";
                    $(".azimuthForm output").css("margin-left", "15px");
                }
                else {
                    if (value > -10) {
                        $(".azimuthValue").css("margin-right", "7px");
                        $(".azimuthForm output").css("margin-left", "12px");
                    }
                    else {
                        $(".azimuthValue").css("margin-right", "1px");
                        $(".azimuthForm output").css("margin-left", "9px");
                    }
                    cardinal = "O";
                }
            }
            $(".azimuthValue").text($this.val() + "º" + cardinal);
        });
        $(".add-area").on("click", function () {
            _this.nAreas += 1;
            if (_this.nAreas == 1) {
                $(".reset-map-drawings").attr("data-i18n", "[title]step2.DELETE_AREAS");
                $(".reset-map-drawings span").attr("data-i18n", "step2.DELETE_AREAS");
                $("#step2").localize();
            }
            $(".tools ul").hide();
            _this.createDrawingManager();
            _this.addListenersTodrawingManager();
        });
        $(".map-collapse").on("click", function () {
            $(this).toggleClass("collapsed");
            $(".map-information .user-information").collapse("toggle");
        });
        $(".close-information").on("click", function () {
            $(".map-instructions").hide();
        });
    };
    Mapa.prototype.calculateTiltAzimuth = function (name, value) {
        var surfaceTilt;
        var modulesTilt;
        var tilt;
        var surfaceAzimuth;
        var azimuth;
        switch (name) {
            case "tilt":
                surfaceTilt = value;
                if (value == 0) {
                    modulesTilt = 20;
                }
                else {
                    modulesTilt = 0;
                }
                tilt = modulesTilt + surfaceTilt;
                break;
            case "azimuth":
                this.currentSurfaceAzimuth = value;
                surfaceTilt = 20;
                break;
        }
        surfaceAzimuth = this.currentSurfaceAzimuth;
        azimuth = surfaceAzimuth;
        return {
            surfaceTilt: surfaceTilt,
            modulesTilt: modulesTilt,
            tilt: tilt,
            surfaceAzimuth: surfaceAzimuth,
            azimuth: azimuth,
        };
    };
    /**
     * Calculate the module row distance separation
     * @param area Target module area
     */
    Mapa.prototype.getDistanceModules = function (area) {
        /** Module length */
        var L = this.product.properties.length / 1000;
        /** Module tilt */
        var B = area.south
            ? area.modulesTilt + area.surfaceTilt
            : area.modulesTilt;
        /** Area surface tilt */
        var i = area.surfaceTilt;
        /** Solar declination degrees  */
        var solarDeclination = -23.45;
        /** PowerPlant area latitude*/
        var lat = area.polyshapeArray[0].lat;
        /** Solar height degrees*/
        var h = 90 - lat + solarDeclination;
        /** Module projection above the surface */
        var d2 = L * Math.cos(this.degreesToRadians(area.south ? B - i : B + i));
        /** Module row distance*/
        var d1 = L *
            (Math.sin(this.degreesToRadians(area.south ? B - i : B + i)) /
                Math.tan(this.degreesToRadians(area.south ? h + i : h - i))) *
            area.moduleMatrixVertical;
        /** Row distance form the beginning of the module */
        var d = d1 + d2;
        return d1;
    };
    /**
     * Redraws the modules in the selected area
     * called when the tilt or azimuth has changed or when the consumption has been recalculated
     * @param {object} data Tilt and azimuth data
     */
    Mapa.prototype.reDrawModules = function (data) {
        for (var _i = 0, _a = this.modulesPolygons; _i < _a.length; _i++) {
            var moduleRemoved = _a[_i];
            moduleRemoved.setMap(null);
        }
        this.modulesPolygons = [];
        this.modulesPolygonsLayout = [];
        for (var _b = 0, _c = this.moduleArea; _b < _c.length; _b++) {
            var moduleArea = _c[_b];
            if (data) {
                moduleArea.surfaceTilt = data.surfaceTilt;
                moduleArea.modulesTilt =
                    data.modulesTilt || data.modulesTilt == 0
                        ? data.modulesTilt
                        : moduleArea.modulesTilt;
                moduleArea.tilt = moduleArea.surfaceTilt + moduleArea.modulesTilt;
                moduleArea.surfaceAzimuth = data.surfaceAzimuth;
                moduleArea.azimuth = data.azimuth;
                moduleArea.structureId = this.inclinationStructure;
            }
            var areaPolygon = _.find(this.polygons, function (polygon) {
                return polygon.id == moduleArea.id;
            });
            moduleArea.columnDistance = this.getDistanceModules(moduleArea);
            var layoutData = this.mapLayout.drawModules(true, moduleArea, areaPolygon, this.modulesPolygons, this.polygons, this.moduleArea);
            moduleArea.maxModules = layoutData.maxModules;
            this.totalModules = layoutData.totalModules;
            this.totalPower = layoutData.totalPower;
            this.totalSurface = layoutData.totalSurface;
            this.createModulesObject(moduleArea);
        }
    };
    Mapa.prototype.removeVertex = function (poly, vertex) {
        var path = poly.getPath();
        path.removeAt(vertex);
    };
    Mapa.prototype.getStaticMap = function (surfaceCoordinates) {
        var url = "https://maps.googleapis.com/maps/api/staticmap?";
        url += "key=AIzaSyBZO6984SQ0MRqg4sXTERCFObTQz9fHYtE";
        if (this.areaM2.reduce(function (a, b) {
            return a + b;
        }, 0) < 400) {
            url += "&zoom=19";
        }
        else if (this.areaM2.reduce(function (a, b) {
            return a + b;
        }, 0) < 1500) {
            url += "&zoom=18";
        }
        else {
            url += "&zoom=17";
        }
        url += "&size=360x130";
        url += "&maptype=satellite";
        url += "&sensor=false";
        //url += '&path=';
        for (var i = 0; i < surfaceCoordinates.length; i++) {
            url += "&path=color:0x017ebaff|weight:2|fillcolor:0x86dcfb80|";
            for (var j = 0; j < surfaceCoordinates[i].length; j++) {
                if (j != 0)
                    url += "|";
                url +=
                    surfaceCoordinates[i][j].lat + "," + surfaceCoordinates[i][j].lng;
            }
            url +=
                "|" + surfaceCoordinates[i][0].lat + "," + surfaceCoordinates[i][0].lng;
        }
        document.getElementById("imgMapa").setAttribute("src", url);
        //http://maps.googleapis.com/maps/api/staticmap?center=33.402285,-111.94271500000002&zoom=20&size=600x600&maptype=satellite&sensor=false&path=color%3ared%7Cweight:1%7Cfill%3awhite%7C33.4022475,-111.9426775%7C33.4022475,-111.9427525%7C33.4023225,-111.9427525%7C33.4023225,-111.9426775%7C33.4022475,-111.9426775
    };
    Mapa.prototype.showCalculateSavings = function (response) {
        var num_fix = 1;
        $("#step2 .user-information .unit span:first").text((response.power / 1000).toFixed(num_fix).replace(".", ","));
        $("#step2 .user-information .kilowatt-max span:first").text(this.numberWithPoints(response.production));
        $("#step2 .user-information .eur span:first").text(this.numberWithPoints(response.annualSavings));
        $("#step2 .user-information .co2 span:first").text(response.estimatePrice
            ? this.numberWithPoints(response.estimatePrice)
            : "*");
        $("#step3 .unit span:first").text((response.power / 1000).toFixed(num_fix).replace(".", ","));
        $("#step3 .kilowatt-max span:first").text(this.numberWithPoints(response.production));
        $("#step3 .eur span:first").text(this.numberWithPoints(response.annualSavings));
        $("#step3 .user-information .moduleNumber").text(this.numberWithPoints(response.modulesNumber));
        $("#step3 .user-information .powerPerModule").text(this.numberWithPoints(response.powerPerModule));
        $("#step3 .co2 span:first").text(response.estimatePrice
            ? this.numberWithPoints(response.estimatePrice)
            : "*");
        if (!response.estimatePrice) {
            $("#areaSmallError").modal("show");
            $("#submitStep2 .send-button").prop("disabled", true);
        }
        else {
            $("#submitStep2 .send-button").prop("disabled", false);
        }
        this.waitingResponse = false;
        if (!this.waitingResponse) {
            $(".spinner").hide();
            $(".resp-inf").show();
        }
    };
    Mapa.prototype.setMapLabel = function (polygonBounds, num) {
        this.mapLabel[num].set("position", this.getCenterInPolygon(polygonBounds));
        this.mapLabel[num].set("text", this.numberWithPoints(this.areaM2[num]) + " m2");
    };
    /**
     * Returns the number of active modules in one area
     * @param layoutModules powerplant layout
     * @param area powerplant area
     * @returns number of active modules or 0
     */
    Mapa.prototype.getNumberActiveModulesByArea = function (layoutModules, area) {
        var numberModulesByArea = _.countBy(layoutModules, function (obj) {
            return obj.areaId === area.id && obj.active === true;
        });
        return (numberModulesByArea.true *
            area.moduleMatrixHorizontal *
            area.moduleMatrixVertical || 0);
    };
    /**
     * Parse data to send layoutData with the moduleArea on calculate Savings
     * @param layout powerplant layout
     */
    Mapa.prototype.parseDataModuleArea = function (layout) {
        var data = [];
        for (var _i = 0, _a = layout.moduleArea; _i < _a.length; _i++) {
            var area = _a[_i];
            var totalActiveModules = this.getNumberActiveModulesByArea(layout.modules, area);
            data.push({
                moduleId: area.moduleId,
                structureId: area.structureId,
                modulePower: area.modulePower,
                activeModules: area.activeModules,
                totalActiveModules: totalActiveModules,
            });
        }
        return { moduleArea: data };
    };
    Mapa.prototype.getCenterInPolygon = function (polygonBounds) {
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < polygonBounds.length; i++) {
            bounds.extend(polygonBounds.getAt(i));
        }
        bounds.extend(polygonBounds.getAt(polygonBounds.length - 1));
        var movemaplat = bounds.getCenter().lat();
        var movemaplng = bounds.getCenter().lng();
        var myLatlng = new google.maps.LatLng(movemaplat, movemaplng);
        return myLatlng;
    };
    Mapa.prototype.calculateSavings = function () {
        var _a = this.typeKits == "batteries"
            ? this.getModulesDrown("batteries")
            : this.phase == "1"
                ? this.getModulesDrown("monophasic")
                : this.getModulesDrown("triphasic"), modules = _a[0], power = _a[1];
        this.mapLayout.setMaxActiveModules(modules);
        this.reDrawModules();
        var powerText = (power / 1000).toFixed(1).replace(".", ",");
        $(".power-installed").text(powerText);
        if (this.moduleArea && this.moduleArea.length > 0) {
            this.waitingResponse = true;
            if (this.waitingResponse) {
                $(".spinner").show();
                $(".resp-inf").hide();
            }
            var that = this;
            var params = {
                data: JSON.parse(JSON.stringify(this.offer)),
                channelId: this.offer.channelId,
                templateCode: config_1.config.context.templateCode,
            };
            params.data.tariff = this.offer.powerPlantData.supplyPoint.tariffName;
            params.data.contractedPower =
                this.offer.powerPlantData.supplyPoint.contractedPower;
            params.data.slopedRoof = 0;
            params.data.surface = this.areaM2.reduce(function (a, b) {
                return a + b;
            }, 0);
            params.data.coordinates = {
                lat: this.coordinates.lat(),
                lng: this.coordinates.lng(),
            };
            params.data.elevation = this.elevation;
            params.data.power = this.totalPower;
            params.data.phases = config_1.config.context.defaultPhases;
            params.data.modulesNumber = this.totalModules;
            params.data.powerPerModule = this.totalPower / this.totalModules;
            params.data.layoutData = this.parseDataModuleArea({
                modules: this.modulesPolygonsLayout,
                moduleArea: this.moduleArea,
            });
            params.data.notes = this.typeKits == "batteries" ? "\nCon baterías" : "";
            params.data.tilt = 20;
            params.data.azimuth = this.moduleArea[0].azimuth;
            params.data.supplyPointData = { phase: config_1.config.context.defaultPhases };
            var headers = {};
            if (config_1.config.apiKey) {
                headers = {
                    "X-Access-Token": config_1.config.apiKey,
                };
            }
            if (params.data.powerPlantData && params.data.powerPlantData.map)
                delete params.data.powerPlantData.map;
            $.ajax({
                url: config_1.config.backendHost + "/PowerPlants/estimated-savings",
                type: "GET",
                dataType: "json",
                headers: headers,
                data: params,
                contentType: "application/json",
                success: function (response) {
                    $("#modulesWarningArea").modal("show");
                    that.estimatedSavingsId = response.id;
                    that.showCalculateSavings(response);
                },
                error: function (response) { },
            });
        }
    };
    Mapa.prototype.sort = function (numbers, needle) {
        var sortedArray = numbers.sort(function (a, b) {
            return Math.abs(needle - a) - Math.abs(needle - b);
        });
        return sortedArray;
    };
    /**
     * Get the monophasic/triphasic modules (and its power) to draw
     * @returns {array} Modules and power
     */
    Mapa.prototype.getModulesDrown = function (type) {
        var maxActiveModules = this.moduleArea && this.moduleArea.length > 0
            ? this.moduleArea[0].maxModules
            : 0;
        var that = this;
        var modulesSort = [];
        var modules;
        var power;
        var modulesNumbers = [];
        for (var _i = 0, _a = this.parsedKits[type]; _i < _a.length; _i++) {
            var parsedKit = _a[_i];
            modulesNumbers.push(parsedKit.modulesNumber);
        }
        modulesSort = that.sort(modulesNumbers, that.mapLayout.activeModules);
        if (modulesSort[0] > that.mapLayout.activeModules) {
            modules = modulesSort[1];
        }
        else {
            modules = modulesSort[0];
        }
        var kitPower = _.find(this.parsedKits[type], function (kit) {
            return kit.modulesNumber === modules;
        });
        if (kitPower)
            power = modules * kitPower.modulesPower;
        else
            power = 0;
        return [modules, power];
    };
    /**
     * Checks the consumption range (in order to get the kit) in order to set the power and get the max modules to draw
     * @returns {number} Max modules to draw
     */
    Mapa.prototype.checkConsumptionRange = function () {
        var that = this;
        var consumption = that.offer.powerPlantData.supplyPoint.totalConsumption;
        var _a = that.phase === "1"
            ? that.typeKits == "batteries"
                ? this.getMonophasicBatteryModulesPower(consumption)
                : this.getMonophasicModulesPower(consumption)
            : this.getTriphasicModulesPower(consumption), modules = _a[0], power = _a[1];
        var powerText = (power / 1000).toFixed(1).replace(".", ",");
        $(".power-installed").text(powerText);
        $("#step2 .recommenderValue").text(powerText);
        return modules;
    };
    /**
     * Gets the monophasic modules and power of the kit that matches with the consumption range
     * @param {number} consumption Current client consumption
     * @returns {array} modules and power of the kit
     */
    Mapa.prototype.getMonophasicBatteryModulesPower = function (consumption) {
        var modules;
        var power = 0;
        if (consumption >= 5000) {
            modules = 10;
            power = 4100;
        }
        else if (consumption < 5000) {
            modules = 8;
            power = 3280;
        }
        return [modules, power];
    };
    /**
     * Gets the monophasic modules and power of the kit that matches with the consumption range
     * @param {number} consumption Current client consumption
     * @returns {array} modules and power of the kit
     */
    Mapa.prototype.getMonophasicModulesPower = function (consumption) {
        var modules;
        var power = 0;
        if (consumption >= 7000) {
            modules = 15;
            power = 6150;
        }
        else if (consumption >= 5000 && consumption < 7000) {
            modules = 10;
            power = 4100;
        }
        else if (consumption >= 3500 && consumption < 5000) {
            modules = 8;
            power = 3280;
        }
        else if (consumption >= 0 && consumption < 3500) {
            modules = 5;
            power = 2050;
        }
        return [modules, power];
    };
    /**
     * Gets the triphasic modules and power of the kit that matches with the consumption range
     * @param {number} consumption Current client consumption
     * @returns {array} modules and power of the kit
     */
    Mapa.prototype.getTriphasicModulesPower = function (consumption) {
        var modules;
        var power = 0;
        if (18000 <= consumption) {
            modules = 42;
            power = 16800;
        }
        else if (12000 <= consumption && consumption < 18000) {
            modules = 25;
            power = 10000;
        }
        else if (9000 <= consumption && consumption < 12000) {
            modules = 18;
            power = 7200;
        }
        else if (5000 <= consumption && consumption < 9000) {
            modules = 12;
            power = 4800;
        }
        return [modules, power];
    };
    /**
     * Javascript ObjectId generator
     * @returns A mongo id with the current time
     */
    Mapa.prototype.mongoObjectId = function () {
        var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
        return (timestamp +
            "xxxxxxxxxxxxxxxx"
                .replace(/[x]/g, function () {
                return ((Math.random() * 16) | 0).toString(16);
            })
                .toLowerCase());
    };
    /**
     * Convert degrees to radians
     * @param degrees Input degrees to convert
     */
    Mapa.prototype.degreesToRadians = function (degrees) {
        return (degrees * Math.PI) / 180;
    };
    /**
     * Calculates the recomendation modules and power in the area drown
     */
    Mapa.prototype.calculateRecommend = function () {
        var totalModules = this.checkConsumptionRange();
        this.mapLayout.setMaxActiveModules(totalModules);
        this.reDrawModules();
        this.calculateSavings();
        $("#step2 .recommender").css("display", "flex");
    };
    return Mapa;
}());
exports.Mapa = Mapa;
