"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.config = void 0;
var messages_es_1 = require("./messages_es");
var messages_cat_1 = require("./messages_cat");
window.adperfTracker = window.adperfTracker || {};
exports.config = {
    apiKey: APIKEY,
    backendHost: BACKENDHOST,
    backendPoint: BACKENDHOST + "/Wizards/submit&queue=true",
    context: {
        defaultCountryCode: "ES",
        channelCode: "WEB_OFERTA",
        templateCode: "CALCULOS_ESTIMATED_SAVINGS_COMERCIALES",
        wizardCode: "ENDESA_B2C_SITES",
        urlOk: "https://www.endesaxstore.com/es/es/thank-you-page-simulador-solar.html",
        urlContact: "https://pro-sites.wattwin.com/sites/endesa/formulario_endesa_b2c_call/form.html?lng=cast",
        domainId: "5719f8f025de438b6d301807",
        solutionCode: "VENTAS_FV_COMERCIALES",
        moduleRef: "JA SOLAR 405W",
        moduleIdCanarias: "6241e3e90582cc0f86e6c958",
        moduleIdResto: "6756caf31099a51b5f50c753",
        flatStructureRef: "01V2",
        inclinationStructureRef: "08V2",
        defaultVoltage: "1X230",
        defaultPhases: "1",
        defaultProfileRef: "ESTIMATE00INDUSTRIA24H",
        defaultAnnualPowerPriceIncrease: 0.2,
        defaultAnnualPerformanceLoss: 0.4,
        defaultAnnualPowerConsumptionIncrease: 0,
        financingType: "none",
        defaultTariff: "2.0TD",
        individualTariff: "2.0TD",
        organizationTariff: "3.0TD",
        defaultContractedPower: 4.7,
        individualContractedPower: 4.7,
        organizationContractedPower: 30,
        individualMaxConsume: 400,
        organizationMaxConsume: 10000,
        individualDefaultConsume: 75,
        organizationDefaultConsume: 800,
        defaultModulesTilt: 15,
        defaultModulesAzimuth: 0,
        regionAutocomplete: "es",
        individualMaxWpConsume: 150,
        organizationMaxWpConsume: 520,
        individualDefaultWpConsume: 10,
        organizationDefaultWpConsume: 66,
    },
    formMessages: {
        es: messages_es_1.messages_es,
        cat: messages_cat_1.messages_cat,
    },
    thankYouPages: {
        "Web-Xstore-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-wb",
        "Paid_Search-Google-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-sem",
        "Display-PMAX-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-pm",
        "Soc_Paid-Facebook-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-fb",
        "Soc_Paid-Instagram-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-ig",
        "Display-Discovery-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-dv",
        "Referral-EE_Contenidos-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-ee",
        "Referral-Habitissimo-Local_eHome_2023Q2_ES-Link_Simulador-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-hb",
        "Display-Programatica-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-pg",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Link_Simulador_Cas_AP-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-cas-ap",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Link_Simulador_Cas_GC-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-cas-gc",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Link_Simulador_Cat_AP-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-cat-ap",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Link_Simulador_Cat_GC-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-cat-gc",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Landing_Simulador_Cat_AP-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-cat-ap-lp",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Landing_Simulador_Cat_GC-Solar": "https://www.endesaxstore.com/es/es/campaign/thank-you-page-simulador-solar-cat-gc-lp",
    },
    campaignCode: {
        "Web-Xstore-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "SOLAR_2023_WEB",
        "Paid_Search-Google-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "SOLAR_2023_SEM",
        "Display-PMAX-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "SOLAR_2023_PM",
        "Soc_Paid-Facebook-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "SOLAR_2023_FB",
        "Soc_Paid-Instagram-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "SOLAR_2023_IG",
        "Display-Discovery-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "SOLAR_2023_DV",
        "Referral-EE_Contenidos-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "SOLAR_2023_EE",
        "Referral-Habitissimo-Local_eHome_2023Q2_ES-Link_Simulador-Solar": "SOLAR_2023_HB",
        "Display-Programatica-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "SOLAR_2023_PG",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Link_Simulador_Cas_AP-Solar": "SOLAR_2023_DEM_CAS_AP",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Link_Simulador_Cas_GC-Solar": "SOLAR_2023_DEM_CAS_GC",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Link_Simulador_Cat_AP-Solar": "SOLAR_2023_DEM_CAT_AP",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Link_Simulador_Cat_GC-Solar": "SOLAR_2023_DEM_CAT_GC",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Landing_Simulador_Cat_AP-Solar": "SOLAR_2023_DEM_LANDING_CAT_AP",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Landing_Simulador_Cat_GC-Solar": "SOLAR_2023_DEM_LANDING_CAT_GC",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Landing_Simulador_Cas_AP-Solar": "SOLAR_2023_DEM_LANDING_CAS_AP",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Landing_Simulador_Cas_GC-Solar": "SOLAR_2023_DEM_LANDING_CAS_GC",
    },
    campaignPlaform: {
        "Web-Xstore-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "Simulador_Xstore",
        "Paid_Search-Google-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "Simulador_SEM",
        "Display-PMAX-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "Simulador_PMAX",
        "Soc_Paid-Facebook-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "Simulador_Facebook",
        "Soc_Paid-Instagram-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "Simulador_Instagram",
        "Display-Discovery-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "Simulador_Discovery",
        "Referral-EE_Contenidos-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "Simulador_EE",
        "Referral-Habitissimo-Local_eHome_2023Q2_ES-Link_Simulador-Solar": "Simulador_Habitissimo",
        "Display-Programatica-Local_eHome_2023Q2_ES-Banner_Simulador-Solar": "Simulador_Programatica",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Link_Simulador_Cas_AP-Solar": "Simulador_DEM_Castellano_AP",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Link_Simulador_Cas_GC-Solar": "Simulador_DEM_Castellano_GC",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Link_Simulador_Cat_AP-Solar": "Simulador_DEM_Catalan_AP",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Link_Simulador_Cat_GC-Solar": "Simulador_DEM_Catalan_GC",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Landing_Simulador_Cat_AP-Solar": "Simulador_DEM_Landing_Catalan_AP",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Landing_Simulador_Cat_GC-Solar": "Simulador_DEM_Landing_Catalan_GC",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Landing_Simulador_Cas_AP-Solar": "Simulador_DEM_Landing_Castellano_AP",
        "DEM-Email_EE-Local_eHome_2023Q2_ES-Landing_Simulador_Cas_GC-Solar": "Simulador_DEM_Landing_Castellano_GC",
    },
    provinces: [
        "Araba/Álava",
        "Albacete",
        "Alicante/Alacant",
        "Almería",
        "Ávila",
        "Badajoz",
        "Balears, Illes",
        "Barcelona",
        "Burgos",
        "Cáceres",
        "Cádiz",
        "Castellón/Castelló",
        "Ciudad Real",
        "Córdoba",
        "Coruña, La",
        "Cuenca",
        "Girona",
        "Granada",
        "Guadalajara",
        "Gipuzkoa",
        "Huelva",
        "Huesca",
        "Jaén",
        "León",
        "Lleida",
        "Rioja, La",
        "Lugo",
        "Madrid",
        "Málaga",
        "Murcia",
        "Navarra",
        "Ourense",
        "Asturias",
        "Palencia",
        "Palmas, Las",
        "Pontevedra",
        "Salamanca",
        "Santa Cruz de Tenerife",
        "Cantabria",
        "Segovia",
        "Sevilla",
        "Soria",
        "Tarragona",
        "Teruel",
        "Toledo",
        "Valencia/València",
        "Valladolid",
        "Bizkaia",
        "Zamora",
        "Zaragoza",
        "Ceuta",
        "Melilla",
    ],
    redirect: true,
    showCalculations: true,
    showInclination: true,
    showLanguageSelector: true,
    showOrientation: true,
    showRecommender: true,
    showModules: true,
    showBatteries: true,
};
